declare global {
  interface Window {
    runConfig?: AppConfig;
  }
}

export interface AppConfig {
  REACT_APP_APPINSIGHTS_INSTR_KEY: string;
  REACT_APP_MSAL_CLIENT_ID: string;
  REACT_APP_MSAL_TENANT_ID: string;
  REACT_APP_MSAL_SIGNUP_POLICY: string;
  REACT_APP_MSAL_SIGNIN_POLICY: string;
  REACT_APP_MSAL_RESETMFA_POLICY: string;
  REACT_APP_MSAL_EXPRESS_VERIFICATION_POLICY: string;
  REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: string;
  REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: string;
  REACT_APP_BASEURL: string;
  REACT_APP_BFF_API_BASEURL: string;
  REACT_APP_BFF_API_SCOPES: string;
  REACT_APP_CUSTOMER_DASHBOARD_URL: string;
  REACT_APP_ENV: string;
  SINGLE_LOG_OUT_URL: string;
  MYSGI_URL: string;
  CLOUD_ROLE_NAME: string;
}

export function getAppConfig(): AppConfig {
  return window.runConfig ?? {
    REACT_APP_APPINSIGHTS_INSTR_KEY: "",
    REACT_APP_MSAL_CLIENT_ID: "",
    REACT_APP_MSAL_TENANT_ID: "",
    REACT_APP_MSAL_SIGNUP_POLICY: "",
    REACT_APP_MSAL_SIGNIN_POLICY: "",
    REACT_APP_MSAL_RESETMFA_POLICY: "",
    REACT_APP_MSAL_EXPRESS_VERIFICATION_POLICY: "",
    REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: "",
    REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: "",
    REACT_APP_BASEURL: "",
    REACT_APP_BFF_API_BASEURL: "",
    REACT_APP_BFF_API_SCOPES: "",
    REACT_APP_CUSTOMER_DASHBOARD_URL: "",
    REACT_APP_ENV: "",
    SINGLE_LOG_OUT_URL: "",
    MYSGI_URL: "",
    CLOUD_ROLE_NAME: "",
  };
}

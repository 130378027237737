import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../Components/PrivateRoute";
import Details from "../Pages/Details";
import { uiPaths } from "../../Properties/appProperties";
import SignIn from "../Pages/SignIn";
import {
  expressVerificationRequest,
  resetMfaRequest,
  signInRequest,
  signUpRequest,
} from "../../Auth/authConfig";
import ResetMFA from "../Pages/ResetMFA";
import { Logout } from "../Pages/Logout";
import DetailsInfo from "../Pages/DetailsInfo";
import NotFound from "../Pages/NotFound";
import Instructions from "../Pages/Instructions";
import ExpressVerification from "../Pages/ExpressVerification";

export default function AllRoutes() {
  return (
    <Routes>
      <Route
        path={uiPaths.DETAILS}
        element={
          <PrivateRoute
            redirectRequest={signUpRequest()}
            element={<Details />}
          />
        }
      />
      <Route
        path={uiPaths.SIGNIN}
        element={
          <PrivateRoute
            redirectRequest={signInRequest()}
            element={<SignIn />}
          />
        }
      />
      <Route
        path={uiPaths.RESETMFA}
        element={
          <PrivateRoute
            redirectRequest={resetMfaRequest()}
            element={<ResetMFA />}
          />
        }
      />
      <Route path={uiPaths.LOGOUT} element={<Logout />} />
      <Route
        path={uiPaths.DETAILSINFO}
        element={
          <PrivateRoute
            redirectRequest={signInRequest()}
            element={<DetailsInfo />}
          />
        }
      />
      <Route
        path={uiPaths.INSTRUCTIONS}
        element={
          <PrivateRoute
            redirectRequest={signUpRequest()}
            element={<Instructions />}
          />
        }
      />
      <Route
        path={uiPaths.EXPRESS}
        element={
          <PrivateRoute
            redirectRequest={expressVerificationRequest()}
            element={<ExpressVerification />}
          />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

import { AuthError } from "@azure/msal-browser";
import { Flex } from "@chakra-ui/react";
import { Typography } from "@sgi/gravity/Typography";
import { Button } from "@sgi/gravity/Button";
import { Link } from "@sgi/gravity";
import { getAppConfig } from "../../../appConfig";

export default function PrivateRouteErrorComponent({ error }: { error: AuthError | null }) {
  const config = getAppConfig();
  const handleBackToMySGIClick = () => window.location.href = config.MYSGI_URL;
  return (
    <Flex
      direction="column"
      maxW={544}
      mx="auto"
      data-testid="customerservice-test"
    >
      <Typography variant="h2" pt={0}>Oops... something went wrong</Typography>
      <Typography variant="body" my={8}>
        Please try the operation again and if the error persist contact us.
        {error?.correlationId && " Correlation ID: " + error.correlationId}
      </Typography>
      <Typography variant="body" mb={8} data-testid="if-you-need-help-test">
        If you need help with your MySGI account, please contact
        the <Link href="https://www.mysgi.ca/help_pages/Technical-Support.html" isExternal>technical support</Link>.
      </Typography>
      <Typography
        variant="body"
        mb={8}
        data-testid="if-you-have-questions-test"
      >
        If you have questions about your policy, please contact your broker.
      </Typography>
      <Button
        my={1}
        isFullWidth
        data-testid="back-button-test"
        onClick={handleBackToMySGIClick}
      >
        Back to MySGI
      </Button>
    </Flex>
  );
}

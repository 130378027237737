import { useState, useCallback } from "react";

import {
  AuthError,
  InteractionType,
  RedirectRequest,
} from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";

const useFetchWithMsal = (msalRequest: RedirectRequest) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | Error | null>(null);
  const [data, setData] = useState<unknown>(null);

  const {
    result,
    error: msalError,
    acquireToken,
  } = useMsalAuthentication(InteractionType.Redirect, msalRequest);

  const execute = async (
    method: string,
    endpoint: string,
    data: unknown = null
  ) => {
    if (msalError) {
      setError(msalError);
      return;
    }

    let idToken = result?.idToken;
    let idTokenClaims = result?.idTokenClaims ?? {};

    const acquireNewToken = async () => {
      const newResult = await acquireToken();
      if (newResult == null) {
        setError(new Error("failed to acquire an access token"));
        return false;
      }
      idToken = newResult.idToken;
      idTokenClaims = newResult.idTokenClaims;
      return true;
    };

    if (!idToken) {
      const success = await acquireNewToken();
      if (!success) return;
    }

    if (!idTokenClaims) {
      const success = await acquireNewToken();
      if (!success) return;
    }

    if (idTokenClaims) {
      if ("exp" in idTokenClaims) {
        const expiration = idTokenClaims.exp as number;
        const now = Math.floor(Date.now() / 1000);
        const expiresIn = expiration - now;

        if (expiresIn < 0) {
          const success = await acquireNewToken();
          if (!success) return;
        }
      }
    }

    try {
      let response;

      const headers = new Headers();
      const bearer = `Bearer ${idToken}`;
      headers.append("Authorization", bearer);

      if (data) headers.append("Content-Type", "application/json");

      let options: RequestInit = {
        method: method,
        headers: headers,
      };
      if (data) options.body = JSON.stringify(data);

      setIsLoading(true);

      response = await (await fetch(endpoint, options)).json();
      setData(response);

      setIsLoading(false);
      return response;
    } catch (e) {
      if (e instanceof Error) setError(e);
      else setError(new Error("failed handling api request"));
      setIsLoading(false);
      throw e;
    }
  };

  return {
    isLoading,
    error,
    data,
    execute: useCallback(execute, [result, msalError, acquireToken]), // to avoid infinite calls when inside a `useEffect`
  };
};

export default useFetchWithMsal;

import {useSessionStorage} from "./useStorage";
import {useCallback} from "react";

export function useBanner() {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const issuer = queryParams.get("issuer");
  const broker = queryParams.get("broker");
  const [issuerId, setIssuerId] = useSessionStorage<string>("ISSUER_BANNER_ID", (existing) => issuer ?? existing);
  const [brokerId, setBrokerId] = useSessionStorage<string>("BROKER_BANNER_ID", (existing) => (issuer ? null : broker) ?? existing);

  const unset = useCallback(() => {
    setIssuerId(null);
    setBrokerId(null);
  }, [setBrokerId, setIssuerId]);

  return { issuerId, brokerId, unset };
}
import React from "react";
import ReactDOM from "react-dom/client";
import { getAppConfig } from "./appConfig";
import { theme } from "@sgi/gravity/theme";
import { ThemeProvider } from "@sgi/gravity/ThemeProvider";
import { initializeAppInsights } from "./appInsights";
import { getMsalInstance } from "./Auth/initializeMsal";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { MsalProvider } from "@azure/msal-react";
import App from "./App";
import "./index.css";

async function main() {
  try {
    const { REACT_APP_APPINSIGHTS_INSTR_KEY, CLOUD_ROLE_NAME } = getAppConfig();
    const { appInsights, reactPlugin } = initializeAppInsights(REACT_APP_APPINSIGHTS_INSTR_KEY, CLOUD_ROLE_NAME);

    const msalInstance = getMsalInstance(appInsights);
    await msalInstance.initialize();

    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );

    root.render(
      <React.StrictMode>
        <AppInsightsContext.Provider value={reactPlugin}>
          <ThemeProvider theme={theme}>
            <MsalProvider instance={msalInstance}>
              <App/>
            </MsalProvider>
          </ThemeProvider>
        </AppInsightsContext.Provider>
      </React.StrictMode>
    );
  } catch (e) {
    console.error(e);
  }
}

(main)();
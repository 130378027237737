import {
  ReactNode,
  Children,
  useState,
  isValidElement,
  cloneElement,
  useEffect,
} from "react";
import { Box } from "@chakra-ui/react";

type TransitionProps = {
  children: ReactNode;
  active?: number;
  maxWidth?: string;
  screenHeight?: string;
};

export default function TransitionWrapper({
  children,
  active,
  maxWidth, 
  screenHeight
}: TransitionProps) {
  const [activeChild, setActiveChild] = useState(0);

  useEffect(() => {
    if (active) {
      setActiveChild(active);
    }
  }, [active]);

  function mapChildren() {
    return Children.map(children, (child, i) => {
      let className = i === activeChild ? "active" : "";
      return (
        <Box
          minWidth="100%"
          width="100%"
          key={child?.toString()}
          data-testid={`carousel-inner-test-${i}`}
          className={className}
        >
          {isValidElement(child) ? (
            <div>{cloneElement(child)}</div>
          ) : (
            <div data-testid={`carousel-invalid-element-test-${i}`}>
              {child}
            </div>
          )}
        </Box>
      );
    });
  }

  return (
    <Box overflow="hidden" data-testid="carousel-test" maxWidth= {maxWidth} minHeight= {screenHeight}>
      <Box
        display="flex"
        transform={`translateX(-${activeChild * 100}%)`}
        transitionDuration="0.7s"
        transitionTimingFunction="ease-in-out"
      >
        {mapChildren()}
      </Box>
    </Box>
  );
}

import {
  PublicClientApplication,
  EventType,
  AuthenticationResult, AuthError,
} from "@azure/msal-browser";
import { getConfig } from "./authConfig";
import { SUCCESSFULSIGNINTRACEMSG } from "../Properties/appInsightsProperties";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";

export function getMsalInstance(appInsights: ApplicationInsights) {
  const msalInstance = new PublicClientApplication(getConfig());

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload && 'account' in event.payload
    ) {
      msalInstance.setActiveAccount(event.payload.account!);
    }
  });

  msalInstance.addEventCallback((event) => {
    const authResult = event.payload as AuthenticationResult;
    if (event.eventType === EventType.LOGIN_SUCCESS && authResult.account) {
      const account = authResult.account;

      // We may want to modify this to use a different property rather than email
      appInsights.setAuthenticatedUserContext(
        account.idTokenClaims?.email as string
      );

      appInsights.trackTrace(
        {
          message: SUCCESSFULSIGNINTRACEMSG,
          severityLevel: SeverityLevel.Information,
        },
        // We may not need this as we're already setting the same in the AuthenticatedUserContext
        { user: account.idTokenClaims?.email as string }
      );
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
      if (event.error) {
        const error = event.error as AuthError;
        appInsights.trackTrace({
          message: `${error.correlationId}: ${error.errorMessage}`,
          severityLevel: SeverityLevel.Warning
        });
      }
      // Catch the cancelled partially self asserted error (partial forgotten password or partial finished sign up )
      // and redirect back to login
      (async () => {
        if (event.error?.message.includes("AADB2C90091")) {
          await msalInstance.loginRedirect();
        }
      })();
    }
  });
  return msalInstance;
}

import {useCustomerDashboardRedirect} from "../Hooks/useCustomerDashboardRedirect";
import {useEffect} from "react";

export default function CustomerDashboardRedirect() {
  const customerDashboardRedirect = useCustomerDashboardRedirect();
  useEffect(() => {
    customerDashboardRedirect();
  }, [customerDashboardRedirect]);
  return null;
}

import React from 'react';
import { Flex } from "@chakra-ui/react";

export default function NotFound() {
  return (
    <Flex
      direction="column"
      alignContent={"center"}
      justifyContent={"center"}
      data-testid="not-found-page"
      py={32}
    >
      The page you are looking for does not exist.
    </Flex>
  );
}
import {
  GlobalFooter,
  GlobalFooterFacebookLink, GlobalFooterInstagramLink,
  GlobalFooterLink, GlobalFooterLinkedInLink,
  GlobalFooterMainLinks,
  GlobalFooterSocials, GlobalFooterXTwitterLink, GlobalFooterYouTubeLink
} from "@sgi/gravity/GlobalFooter";

export const Footer = () => {
  return (
    <GlobalFooter id="footer-test">
      <GlobalFooterMainLinks>
        <GlobalFooterLink href="https://www.sgi.sk.ca" isExternal>
          sgi.sk.ca
        </GlobalFooterLink>
        <GlobalFooterLink href="https://www.sgicanada.ca" isExternal>
          sgicanada.ca
        </GlobalFooterLink>
        <GlobalFooterLink href="https://www.mysgi.ca/help_pages/Terms-and-Conditions.html" isExternal>
          Terms of Use
        </GlobalFooterLink>
        <GlobalFooterLink href="https://www.mysgi.ca/help_pages/Help-Centre.html" isExternal>
          Help Centre
        </GlobalFooterLink>
        <GlobalFooterLink href="https://www.mysgi.ca/help_pages/Privacy.html" isExternal>
          Privacy Statement
        </GlobalFooterLink>
        <GlobalFooterLink href="https://www.mysgi.ca/help_pages/Technical-Support.html" isExternal>
          Technical Support
        </GlobalFooterLink>
      </GlobalFooterMainLinks>
      <GlobalFooterSocials>
        <GlobalFooterFacebookLink href="https://www.facebook.com/SGIcommunity" isExternal/>
        <GlobalFooterInstagramLink href="https://instagram.com/sgiphotos" isExternal/>
        <GlobalFooterYouTubeLink href="https://www.youtube.com/SGIcommunications" isExternal/>
        <GlobalFooterXTwitterLink href="https://twitter.com/#!/SGItweets" isExternal/>
        <GlobalFooterLinkedInLink href="https://www.linkedin.com/company/sgi_autofund/" isExternal/>
      </GlobalFooterSocials>
    </GlobalFooter>
  );
};

export default Footer;

import { Button } from "@sgi/gravity/Button";
import { Typography } from "@sgi/gravity/Typography";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { uiPaths } from "../../Properties/appProperties";
import { useCustomerDashboardRedirect } from "../Hooks/useCustomerDashboardRedirect";

export default function Instructions() {
  const navigate = useNavigate();
  const customerDashboardRedirect = useCustomerDashboardRedirect();
  return (
    <Flex
      direction="column"
      maxW={544}
      padding={38}
      mx="auto"
      alignContent={"center"}
      justifyContent={"center"}
      data-testid="detail-page"
    >
      <Box>
        <Typography variant="h2" data-testid="page-title">
          Add your SGI CANADA policy
        </Typography>
        <Box style={{ position: "relative" }} data-testid="page-main">
          <VStack mt={6}>
            <p>
              You can add your policy only if it was created on or after June
              26, 2024. If you don't know the date, you can use your policy
              number. You can add policies with the 12-character format either
              (H19999999999) or (A19999999999).
              <br />
              <br />
              Your MySGI account email must match the email you provided your
              broker.
              <br />
              <br />
              If you have trouble verifying your details, please contact your
              broker for help.
            </p>
            <br />
            <Button
              isFullWidth
              onClick={() => navigate(uiPaths.DETAILS, { state: true })}
              data-testid="navigate-detailsinfo-button"
            >
              Continue to Add Policy
            </Button>
            <Button
              variant="ghost"
              marginTop="20px"
              marginBottom="80px"
              data-testid="detailsinfo-cancel-button"
              onClick={() => {
                customerDashboardRedirect();
              }}
            >
              Cancel
            </Button>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}

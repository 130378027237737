import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

export const initializeAppInsights = (instrumentationKey: string, cloudRoleName: string) => {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      autoTrackPageVisitTime: true,
      enableAutoRouteTracking: true,
      // Enable correlation
      disableFetchTracking: false,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      correlationHeaderExcludedDomains: [],
      // End of correlation config
      extensions: [reactPlugin],
    },
  });
  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.tags = envelope.tags ?? [];
    envelope.tags.push({ "ai.cloud.role": cloudRoleName });
  });
  return { appInsights, reactPlugin };
};

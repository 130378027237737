import { FunctionComponent } from "react";
import { useBanner } from "../Hooks/useBanner";
import { BrokerIssuerBanner } from "@sgi/gravity/BrokerIssuerBanner";

const ImageBasePath = "https://www.mysgi.ca/images";

export const Banner: FunctionComponent = () => {
  const { issuerId, brokerId } = useBanner();
  return (
    <div className="banner" data-testid="banner-test">
      {issuerId && <BrokerIssuerBanner issuerNumber={issuerId} />}
      {brokerId && <BrokerIssuerBanner brokerNumber={brokerId} />}
      <Vectors />
    </div>
  );
};

const Vectors: FunctionComponent = () => {
  return (
    <div className="vectors">
      <picture>
        <source
          media="(min-width: 1135px)"
          srcSet={`${ImageBasePath}/desktop_blue_vector.svg`}
        />
        <source
          media="(max-width: 1134px)"
          srcSet={`${ImageBasePath}/mobile_blue_vector.svg`}
        />
        <img src={`${ImageBasePath}/desktop_blue_vector.svg`} alt="" />
      </picture>
      <picture>
        <source
          media="(min-width: 1135px)"
          srcSet={`${ImageBasePath}/desktop_yellow_vector.svg`}
        />
        <source
          media="(max-width: 1134px)"
          srcSet={`${ImageBasePath}/mobile_yellow_vector.svg`}
        />
        <img src={`${ImageBasePath}/desktop_yellow_vector.svg`} alt="" />
      </picture>
    </div>
  );
};

export default Banner;

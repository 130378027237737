import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType, RedirectRequest } from "@azure/msal-browser";
import type React from "react";
import { LoadingIndicator } from "@sgi/gravity";
import PrivateRouteErrorComponent from "./PrivateRouteErrorComponent";

interface PrivateRouteProps {
  element: React.JSX.Element;
  redirectRequest: RedirectRequest;
}

export function PrivateRoute({
  element,
  redirectRequest,
}: PrivateRouteProps) {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={redirectRequest}
      loadingComponent={() => <LoadingIndicator />}
      errorComponent={(result) => <PrivateRouteErrorComponent error={result.error} />}
    >
      {element}
    </MsalAuthenticationTemplate>
  );
}

export default PrivateRoute;

import {
  Configuration,
  RedirectRequest,
  LogLevel,
  EndSessionRequest,
} from "@azure/msal-browser";
import { StringDict } from "@azure/msal-common";
import { getAppConfig } from "../appConfig";
import { uiPaths } from "../Properties/appProperties";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export function getConfig(): Configuration {
  const appConfig = getAppConfig();
  const REACT_APP_MSAL_CLIENT_ID = appConfig.REACT_APP_MSAL_CLIENT_ID;
  const authority = appConfig.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT + appConfig.REACT_APP_MSAL_SIGNIN_POLICY;
  const REDIRECT_URL = `${appConfig.REACT_APP_BASEURL}${uiPaths.DETAILS}`;

  return {
    auth: {
      clientId: REACT_APP_MSAL_CLIENT_ID,
      authority,
      knownAuthorities: [appConfig.REACT_APP_MSAL_B2C_AUTHORITYDOMAIN],
      redirectUri: REDIRECT_URL,
      postLogoutRedirectUri: `${appConfig.MYSGI_URL}`,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            case LogLevel.Error:
            default:
              console.error(message);
              return;
          }
        },
      },
    },
  };
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const signInRequest = (): RedirectRequest => ({
  scopes: getScopes(),
  extraQueryParameters: getParams(),
});

export const signUpRequest = (): RedirectRequest => ({
  scopes: getScopes(),
  authority: getAppConfig().REACT_APP_MSAL_POLICY_AUTHORITY_ROOT + getAppConfig().REACT_APP_MSAL_SIGNUP_POLICY,
  extraQueryParameters: getParams(),
});

export const resetMfaRequest = (): RedirectRequest => ({
  scopes: getScopes(),
  authority: getAppConfig().REACT_APP_MSAL_POLICY_AUTHORITY_ROOT + getAppConfig().REACT_APP_MSAL_RESETMFA_POLICY,
  extraQueryParameters: getParams(),
});

export const logoutRequest = (): EndSessionRequest => ({
  postLogoutRedirectUri: getAppConfig().REACT_APP_BASEURL,
});

export const expressVerificationRequest = (): RedirectRequest => ({
  scopes: getScopes(),
  authority: getAppConfig().REACT_APP_MSAL_POLICY_AUTHORITY_ROOT + getAppConfig().REACT_APP_MSAL_EXPRESS_VERIFICATION_POLICY,
  extraQueryParameters: getParams(),
});

export function getScopes(): string[] {
  return ["openid", "offline_access", getAppConfig().REACT_APP_BFF_API_SCOPES];
}

export function getParams(): StringDict {
  const queryParams = new URLSearchParams(window.location.search);
  let stringDict: { [key: string]: string } = {};

  // Iterate over each query parameter and add it to the JSON object
  queryParams.forEach((value, key) => {
    stringDict[key] = value;
  });

  return stringDict;
}

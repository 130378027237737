import {
  useState,
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";

export interface IAppContext {
  sideBarStyle: {
    swooshVisibility: boolean;
    minWidth: string;
  };
}

export const defaultAppContext: IAppContext = {
  sideBarStyle: {
    swooshVisibility: true,
    minWidth: "36vw",
  },
};

export interface IWrapperAppContext {
  context: IAppContext;
  setContext: Dispatch<SetStateAction<IAppContext>>;
}

const baseWrapperAppContext: IWrapperAppContext = {
  context: defaultAppContext,
  setContext: () => {},
};

const AppContext = createContext(baseWrapperAppContext);

export function useAppContext() {
  return useContext<IWrapperAppContext>(AppContext);
}

type WrapperProviderProps = {
  children: ReactNode;
};

export function AppContextProvider({ children }: WrapperProviderProps) {
  const [appContext, setAppContext] = useState<IAppContext>(defaultAppContext);

  const setContext = useCallback((context: SetStateAction<IAppContext>) => {
    setAppContext(context);
  }, []);

  const value = useMemo(
    () => ({
      context: appContext,
      setContext: setContext,
    }),
    [appContext, setContext]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

import { useMemo, useState } from "react";

type Value<T> = T | null;
type Setter<T> = (value: Value<T>) => void;
type ValueOrFactory<T> = Value<T> | ((existing: T | null) => T | null);
type Impl<T> = [Value<T>, Setter<T>];

const getInitialState = <T>(storage: Storage, key: string, initialValue?: ValueOrFactory<T>) => {
  let existingValue: Value<T>;
  try {
    const item = storage.getItem(key);
    existingValue = item ? (JSON.parse(item) as T) : null;
  } catch (error) {
    existingValue = null;
  }
  if (initialValue === undefined) return existingValue;
  try {
    const value = initialValue instanceof Function ? initialValue(existingValue) : initialValue;
    storage.setItem(key, JSON.stringify(value));
    return value;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const createSetter = <T>(storage: Storage, key: string, setStoredValue: Setter<T>) => (value: Value<T>) => {
  try {
    setStoredValue(value);
    if (value == null) {
      storage.removeItem(key);
      return;
    }
    storage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
}

export function useSessionStorage<T>(key: string, initialValue?: ValueOrFactory<T>) : Impl<T> {
  const [storedValue, setStoredValue] = useState(() => getInitialState(window.sessionStorage, key, initialValue));
  const setValue = useMemo(() => createSetter(window.sessionStorage, key, setStoredValue), [key]);
  return [storedValue, setValue];
}

export function useLocalStorage<T>(key: string, initialValue?: ValueOrFactory<T>) : Impl<T> {
  const [storedValue, setStoredValue] = useState(() => getInitialState(window.localStorage, key, initialValue));
  const setValue = useMemo(() => createSetter(window.localStorage, key, setStoredValue), [key]);
  return [storedValue, setValue];
}
